<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div>
      <b-row>
        <b-col cols="12">

          <b-card>

            <h4 class="mb-2">انتخاب جایگاه</h4>

            <b-col
                v-if="sections !== null"
                cols="12"
                md="6"
            >
              <b-form-group
                  label="انتخاب جایگاه"
                  label-for="section"
              >
                <v-select
                    v-model="section"
                    :options="sections"
                    label="name"
                    :reduce="name => name.sectionInfo.sectionId"
                    :clearable="false"
                    input-id="section"
                />
              </b-form-group>
            </b-col>
          </b-card>

          <b-card v-if="rows !== null">

            <h4 class="mb-2">انتخاب صندلی</h4>

            <div class="col-md-2 my-2 ml-auto">

              <div class="d-flex flex-row justify-content-between align-items-center my-1">
                <span>قابل خرید</span>
                <div class="chair-holder">
                  <span class="chair free-chair cursor-none"></span>
                </div>
              </div>

              <div class="d-flex flex-row justify-content-between align-items-center my-1">
                <span>فروخته شده</span>
                <div class="chair-holder">
                  <span class="chair buyed-chair cursor-none"></span>
                </div>
              </div>

              <div class="d-flex flex-row justify-content-between align-items-center my-1">
                <span>رزرو شده (کاربر)</span>
                <div class="chair-holder">
                  <span class="chair reserved-chair cursor-none"></span>
                </div>
              </div>

              <div class="d-flex flex-row justify-content-between align-items-center my-1">
                <span>قابل رزرو توسط ادمین</span>
                <div class="chair-holder">
                  <span class="chair pre-reserved cursor-none"></span>
                </div>
              </div>

              <div class="d-flex flex-row justify-content-between align-items-center my-1">
                <span>انتخاب شما</span>
                <div class="chair-holder">
                  <span class="chair selected-chair cursor-none"></span>
                </div>
              </div>
            </div>

            <div class="w-100 place-div py-3 justify-content-start justify-content-md-center">
              <section class=" place-section">

                <div style="direction: ltr!important;" v-for="(r,ri) in rows" :key="r.sectionInfo.rowId"
                     class="seatRow">

                  <span class="seatRowHeader">{{ r.sectionInfo.rowNumber }}</span>

                  <div v-for="(c,ci) in maxRowCount" class="chair-holder">
            <span
                :aria-data="data(r,ci)"
                :title="price(r,ci)"
                :id="chairId(r,ci)"
                class="chair"
                :class="[isRenderingChair(r,ci),color(r,ci)]"
                @click="addToSelected(chairId(r,ci),data(r,ci))"
            >
              {{ chairNumber(r, ci) }}
            </span>
                  </div>

                  <span class="seatRowHeader">{{ r.sectionInfo.rowNumber }}</span>

                </div>
              </section>
            </div>
          </b-card>

          <b-card>

            <h4 class="mb-2">انتخاب کاربر</h4>

            <div class="m-2">

              <!-- Table Top -->
              <b-row>

                <!-- Search -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="جستجو..."
                    />
                  </div>
                </b-col>
              </b-row>

            </div>

            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="users"
                responsive
                :fields="myTableColumns"
                primary-key="id"
                show-empty
                bordered
                striped
                empty-text="موردی موجود نیست!"
            >

              <template #cell(name)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                        size="45"
                        :src="`https://api.maxterms.com/${data.item.selfieFileData}`"
                        :variant="`success`"
                        :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                    />
                  </template>
                  <b-link
                      :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                      class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.item.name + ' ' + data.item.familyName }}
                  </b-link>
                  <small class="text-muted">@{{ data.item.userId }}</small>
                </b-media>
              </template>

              <!-- Column: type -->
              <template #cell(type)="data">
                <b-badge
                    pill
                    class="text-capitalize"
                    :variant="``"
                >
                  {{ data.item.type }}
                </b-badge>
              </template>


              <!-- Column: state -->
              <template #cell(state)="data">
                <b-badge
                    v-if="data.item.state === 1"
                    pill
                    class="text-capitalize"
                    :variant="`success`"
                >
                  وارد شده
                </b-badge>
                <b-badge
                    v-else-if="data.item.state === 2"
                    pill
                    class="text-capitalize"
                    :variant="`warning`"
                >
                  فالو نکرده
                </b-badge>
                <b-badge
                    v-else-if="data.item.state === 3"
                    pill
                    class="text-capitalize"
                    :variant="`danger`"
                >
                  آیدی اشتباه
                </b-badge>
              </template>

              <!-- Column: edit -->
              <template #cell(select)="data">
                <feather-icon @click="userId = data.item.userId" icon="EditIcon" class="text-primary cursor-pointer"
                              size="20"/>
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
                </b-col>
                <!-- Pagination -->
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalCounts"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon style="transform: rotate(180deg)"
                                    icon="ChevronLeftIcon"
                                    size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>

            <validation-observer
                ref="refFormObserver"
            >

              <b-form>
                <b-row>

                  <!-- Field:  title -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="شناسه کاربر"
                        label-for="userId"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="userId"
                            v-model="userId"
                            :state="errors.length > 0 ? false:null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>

            <b-button
                variant="primary"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="validationForm"
            >
              ثبت
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAlert, BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea,
  BImg,
  BLink, BMedia,
  BRow, BTab, BTabs, BOverlay, BBadge, BTable, BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {SeminarCreateSeminarRequest, SeminarGetPreservedRowsRequest} from "@/libs/Api/Seminar";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from '@validations'
import {SaloonGetAllRequest} from "@/libs/Api/Saloon";
import {SectionGetBySaloonIdAndSeminarIdRequest, SectionGetBySaloonIdRequest} from "@/libs/Api/Section";
import {RowGetAllBySectionIdAndSeminarIdRequest, RowGetAllBySectionIdRequest} from "@/libs/Api/Row";
import Helper from "@/libs/Helper";
import {ReserveCheckReserveIdsRequest, ReserveCreateRequest} from "@/libs/Api/Reserve";
import {OrderCreateForAdminRequest} from "@/libs/Api/Order";
import {UsersGetAllRequest} from "@/libs/Api/Users";

export default {
  name: "SeminarReserve",
  data() {
    return {
      overlay: false,
      seminarId: this.$route.params.id,
      section: 1,
      sections: null,
      selectedRows: null,
      selectedRow: null,
      rows: null,
      maxRowCount: null,
      userId: null,
      selected: [],
      selectedData: [],

      users: null,
      currentPage: 1,
      totalCounts: null,
      perPage: 50,
      perState: '',
      searchQuery: '',
      myTableColumns: [
        {key: 'name', label: 'نام کامل'},
        {key: 'userId', label: 'شناسه کاربر'},
        {key: 'instagramId', label: 'اینستاگرام'},
        {key: 'nationalId', label: 'کد ملی'},
        {key: 'state', label: 'وضعیت'},
        {key: 'mobile', label: 'موبایل'},
        {key: 'select', label: 'انتخاب'},
      ],
    }
  },
  async created() {
    await this.getSections();
    await this.getRows(this.section)
    await this.getUsers(this.perPage, this.currentPage, this.searchQuery, this.perState)
  },
  methods: {
    validationForm() {
      let _this = this;

      _this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          _this.checkReserve();
        }
      })
    },
    async getSections() {
      let _this = this;

      _this.overlay = true;
      _this.sections = null

      let data = {
        saloonId: 1,
        seminarId: _this.seminarId
      }
      let sectionGetBySaloonIdAndSeminarIdRequest = new SectionGetBySaloonIdAndSeminarIdRequest(_this);
      sectionGetBySaloonIdAndSeminarIdRequest.setData(data);
      await sectionGetBySaloonIdAndSeminarIdRequest.fetch(function (content) {
        content.forEach(el => {
          if (el.sectionInfo.level === 1) {
            el.name = `${'همکف ' + el.sectionInfo.name}`;
            el.id = `${'همکف ' + el.sectionInfo.sectionId}`;
          } else {
            el.name = `${'بالکن ' + el.sectionInfo.name}`;
            el.id = `${'همکف ' + el.sectionInfo.sectionId}`;
          }
        })
        _this.sections = content;
        _this.overlay = false;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    // async getPreservedRows() {
    //   let _this = this;
    //   _this.overlay = true;
    //
    //   let seminarGetPreservedRowsRequest = new SeminarGetPreservedRowsRequest(_this);
    //   seminarGetPreservedRowsRequest.setId(_this.seminarId);
    //   await seminarGetPreservedRowsRequest.fetch(function (content) {
    //     content.forEach(el => {
    //       if (el.sectionName.level === 1) {
    //         el.rowNumber = `${el.rowNumber + ' همکف ردیف ' + el.sectionName.name + ' جایگاه '}`
    //       } else {
    //         el.rowNumber = `${el.rowNumber + ' بالکن ردیف ' + el.sectionName.name + ' جایگاه '}`
    //       }
    //     })
    //     _this.overlay = false;
    //     _this.selectedRows = content;
    //   }, function (error) {
    //     _this.overlay = false;
    //     console.log(error)
    //   })
    // },
    async getRows(param) {
      let _this = this;
      _this.overlay = true;

      let defaultGetData = {
        sectionId: param,
        seminarId: _this.seminarId,
      }

      let rowGetAllBySectionIdAndSeminarIdRequest = new RowGetAllBySectionIdAndSeminarIdRequest(_this);
      rowGetAllBySectionIdAndSeminarIdRequest.setData(defaultGetData);
      await rowGetAllBySectionIdAndSeminarIdRequest.fetch(function (content) {
        _this.overlay = false;
        _this.rows = content.data;
        _this.maxRowCount = content.maxRowCount
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async checkReserve() {
      let _this = this;
      let data = {
        reserveIds: _this.selected
      }

      let reserveCheckReserveIdsRequest = new ReserveCheckReserveIdsRequest(_this);
      reserveCheckReserveIdsRequest.setParams(data);
      await reserveCheckReserveIdsRequest.fetch(function (content) {
        if (content === true) {
          _this.setOrder();
        }
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            variant: 'danger',
            text: `صندلی های انتخاب شده رزرو شده اند.`,
          },
        });
        window.location.reload();
      })
    },

    async setOrder() {
      let _this = this;
      let subs = [];

      _this.selectedData.forEach(el => {
        let ele = JSON.parse(el)
        subs.push({
          isDeleted: false,
          orderItemId: 0,
          orderId: 0,
          productId: 0,
          count: 1,
          amount: ele.price,
          reserveId: ele.reserveId
        })
      })

      let data = {
        isDeleted: false,
        orderId: 0,
        isPayed: false,
        description: "خرید بلیط توسط ادمین",
        sumPrice: _this.sumPrice,
        isDelivered: false,
        userId: _this.userId,
        createDate: new Date(),
        status: 1,
        address: null,
        subOrders: subs,
        addressInfo: "",
        isTicket: true,
      }

      _this.showOverlay = true;
      let orderCreateForAdminRequest = new OrderCreateForAdminRequest(_this);
      orderCreateForAdminRequest.setParams(data);
      await orderCreateForAdminRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        });
        window.location.reload();
      }, function (error) {
        console.log(error)
      })

    },

    // Functions For setting chairs
    isRenderingChair(r, ci) {
      if (ci + 1 >= r.sectionInfo.schemaStart && ci + 1 <= r.sectionInfo.schemaEnd) {
        return ""
      } else {
        return "d-none"
      }
    },
    color(row, id) {
      const chairs = row.sectionInfo.seats;
      let cl = '';

      if (id + 1 >= row.sectionInfo.schemaStart && id + 1 <= row.sectionInfo.schemaEnd) {
        let newId = id + 1;
        if (chairs[newId - row.sectionInfo.schemaStart] !== undefined) {
          chairs[newId - row.sectionInfo.schemaStart].reserveInfo.state === 1 ? cl = "free-chair" : "";
          // item.state === 2 ? cl = "selected-chair" : "";
          chairs[newId - row.sectionInfo.schemaStart].reserveInfo.state === 2 ? cl = "reserved-chair" : "";
          chairs[newId - row.sectionInfo.schemaStart].reserveInfo.state === 3 ? cl = "buyed-chair" : "";
          chairs[newId - row.sectionInfo.schemaStart].reserveInfo.state === 4 ? cl = "pre-reserved" : "";
        }
      } else {
        return cl = ''
      }
      return cl
    },
    chairNumber(row, id) {
      const chairs = row.sectionInfo.seats;
      let num = '';
      if (id + 1 >= row.sectionInfo.schemaStart && id + 1 <= row.sectionInfo.schemaEnd) {
        let newId = id + 1;
        if (chairs[newId - row.sectionInfo.schemaStart] !== undefined) {
          return num = chairs[newId - row.sectionInfo.schemaStart].seatNumber
        }
      } else {
        return num = ""
      }
    },
    data(row, id) {
      const chairs = row.sectionInfo.seats;
      let data = '';
      if (id + 1 >= row.sectionInfo.schemaStart && id + 1 <= row.sectionInfo.schemaEnd) {
        let newId = id + 1;
        if (chairs[newId - row.sectionInfo.schemaStart] !== undefined) {
          return data = JSON.stringify(chairs[newId - row.sectionInfo.schemaStart].reserveInfo)
        }
      } else {
        return data = ""
      }
    },
    price(row, id) {
      const chairs = row.sectionInfo.seats;
      let price = '';
      if (id + 1 >= row.sectionInfo.schemaStart && id + 1 <= row.sectionInfo.schemaEnd) {
        let newId = id + 1;
        if (chairs[newId - row.sectionInfo.schemaStart] !== undefined) {
          return price = `${Helper.prettyPrice(JSON.stringify(chairs[newId - row.sectionInfo.schemaStart].reserveInfo.price), 0) + ' تومان'}`
        }
      } else {
        return price = ""
      }
    },
    chairId(row, id) {
      const chairs = row.sectionInfo.seats;
      let num = '';
      if (id + 1 >= row.sectionInfo.schemaStart && id + 1 <= row.sectionInfo.schemaEnd) {
        let newId = id + 1;
        if (chairs[newId - row.sectionInfo.schemaStart] !== undefined) {
          return num = chairs[newId - row.sectionInfo.schemaStart].reserveInfo.reserveId
        }
      } else {
        return num = ""
      }
    },
    addToSelected(param, data) {
      let newData = JSON.parse(data)
      let _this = this;

      function isAdded(array) {
        return array === param;
      }

      function isAdded2(array) {
        return array === data;
      }

      if (_this.selectedData.find(isAdded2)) {
        _this.selectedData = Helper.arrayRemove(_this.selectedData, data);
      } else {
        _this.selectedData.push(data);
      }

      if (_this.selected.find(isAdded)) {
        _this.selected = Helper.arrayRemove(_this.selected, param);
        // _this.sumPrice -= newData.price;
        document.getElementById(param).classList.remove('selected-chair');
        return _this.selected
      } else {
        let classList = document.getElementById(param).classList;
        if (classList.contains("pre-reserved") || classList.contains("free-chair")) {
          _this.selected.push(param);
          document.getElementById(param).classList.add('selected-chair');
          // _this.sumPrice += newData.price;
        }
      }
    },
    async getUsers(count, pageNumber, search, state) {
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber: pageNumber,
        count: 4,
        search: search,
        state: state
      }

      let usersGetAllRequest = new UsersGetAllRequest(_this);
      usersGetAllRequest.setParams(data);
      await usersGetAllRequest.fetch(function (content) {
        _this.overlay = false;
        _this.users = content.users;
        _this.totalCounts = content.usersCount;
      }, function (error) {
        _this.overlay = false;
        console.log(error);
      })
    }
  },
  watch: {
    section: function (nv, ov) {
      this.getRows(nv)
    },
    currentPage: function (nv, ov) {
      this.getUsers(this.perPage, nv, this.searchQuery, this.perState);
    },
    searchQuery: function (nv, ov) {
      this.getUsers(this.perPage, this.currentPage, nv, this.perState);
    },
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    vSelect,
    BOverlay,
    BBadge,
    BTable,
    BPagination,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
}
</script>

<style scoped>
.cursor-none {
  cursor: auto !important;
}

.place-div {
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.place-section {
  text-align: Center;
  width: 1100px;
  min-height: 300px;
  /*background-color: #4a4a4a;*/
}

.seatRow {
  direction: ltr !important;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-flow: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.chair-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin-right: 2px;
  margin-left: 2px;
  text-align: center;
  align-content: center;
  color: white;
}

.chair {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: .5rem;
  background-color: #3ba58a;
  box-shadow: 1px 1px 3px gray;
  justify-content: center;
  align-items: center;
  transition: 200ms;
}

.seatRowHeader {
  /*padding: 3px;*/
  font-size: 20px;
  width: 6rem;
  /*height: 2rem;*/
  text-align: center;
  display: inline-block;
}

.free-chair {
  background-color: #3ba58a;
  cursor: pointer;
}

.free-chair:hover {
  box-shadow: 3px 3px 6px gray;
}

.selected-chair {
  background-color: #ffdc1e !important;
  cursor: pointer;
}

.buyed-chair {
  background-color: #bd2130;
  cursor: not-allowed;
}

.reserved-chair {
  background-color: #fd7e14;
  cursor: not-allowed;
}

.pre-reserved {
  background-color: #5e5873;
  cursor: pointer;
}

.side-box {
  width: 200px;
  background-color: #2dbbc4;
}
</style>
